import React, { Fragment, useState } from "react";
import { Button, SECONDARY, SMALL } from "../buttons/button";
import {
  GenerateVttStatus,
  MediaPipeline,
  Mutation,
  MutationReplaceMeetingRecordingArgs,
} from "../../types";
import { convertUTCtoDateTime } from "../../utils/dateUtils";
import { Checkbox } from "../inputs/checkbox";
import { getObject } from "../../utils/s3Utils";
import config from "../../utils/config";
import { consoleNonProd, download } from "../../utils/utilityBeltUtils";
import { IconButton } from "../buttons/iconButton";
import { useMutation } from "@apollo/client";
import { REPLACE_MEETING_RECORDING } from "../../graphql/mutations";
import { UploadModal } from "../uploadModal";
import { validateVideoFileType } from "../../utils/validators";

type PipelineDetailsProps = {
  mediaPipeline: MediaPipeline;
  key: number;
  handleSelectReplayRecording: (mp: MediaPipeline) => void;
  selectedReplayRecording: MediaPipeline;
  onComplete: () => void;
};

export const PipelineDetails: React.FC<PipelineDetailsProps> = ({
  mediaPipeline,
  key,
  handleSelectReplayRecording,
  selectedReplayRecording,
  onComplete,
}) => {
  const [showError, setShowError] = useState(false);
  const [showUploadForm, setShowUploadForm] = useState(false);

  const [replaceMeetingRecordingMutation, { error }] = useMutation(
    REPLACE_MEETING_RECORDING,
    {
      async onCompleted(data: Pick<Mutation, "replaceMeetingRecording">) {
        onComplete();
      },
      onError(error) {
        consoleNonProd("Error in replace meeting recording mutation:", error);
        setShowError(true);
      },
    }
  );

  const toggleUploadForm = () => setShowUploadForm(!showUploadForm);

  const handleDismiss = () => {
    setShowError(false);
    toggleUploadForm();
  };

  const handleReplaceRecording = () => {
    setShowUploadForm(true);
  };

  const handleDownloadChat = async () => {
    const date = convertUTCtoDateTime(mediaPipeline.createdAt).replaceAll(
      /[\/\s\:\,]/gm,
      "-"
    );
    const filename = `${date}_chat.txt`;
    const chatObjKey = `${mediaPipeline.awsConcatPipelineId}/logs/chat-log.txt`;
    try {
      const resp = await getObject(config.concatS3Bucket, chatObjKey);
      // @ts-ignore
      const content = await resp.body.transformToString();
      download(filename, content);
    } catch (e) {
      consoleNonProd(
        `Error downloading chat for pipeline ID ${mediaPipeline.id} from S3:`,
        e
      );
    }
  };

  const handleDownloadAttendees = async () => {
    const filename = `attendee_log_${mediaPipeline.awsConcatPipelineId}.csv`;
    const attendeesObjKey = `${mediaPipeline.awsConcatPipelineId}/logs/attendee_log_${mediaPipeline.awsConcatPipelineId}.csv`;
    try {
      const resp = await getObject(config.concatS3Bucket, attendeesObjKey);
      // @ts-ignore
      const content = await resp.body.transformToString();
      download(filename, content);
    } catch (e) {
      consoleNonProd(
        `Error downloading attendee log for pipeline ID ${mediaPipeline.id} from S3:`,
        e
      );
    }
  };

  const handleUploadComplete = (id) => {
    let input: MutationReplaceMeetingRecordingArgs = { id };
    replaceMeetingRecordingMutation({ variables: input });
  };

  const validateFileType = (
    fileName: string
  ): { valid: boolean; message?: string } => {
    const valid = validateVideoFileType(fileName);

    return {
      valid,
      message: !valid ? 'Only ".mp4" files are accepted.' : undefined,
    };
  };

  const generateS3Key = (mediaPipeline: MediaPipeline): string => {
    return (
      mediaPipeline.awsConcatPipelineId +
      "/replace-video/" +
      mediaPipeline.awsConcatPipelineId +
      "__replace.mp4"
    );
  };

  return (
    <Fragment>
      <tr className="recording-details__table__row">
        <td className="recording-details__table__cell">
          <span className="recording-details__date">
            {convertUTCtoDateTime(mediaPipeline.createdAt)}
          </span>
          <div className="recording-details__meta">
            <span>Pipeline id: {mediaPipeline.id}</span>
            <span>Concat folder: {mediaPipeline.awsConcatPipelineId}</span>
          </div>

          <br />
          <Button
            id={"replace_recording_" + key}
            inputClass="recording-details__replace-recording"
            btnType={SECONDARY}
            disabled={false}
            size={SMALL}
            text="Replace recording"
            icon="film"
            onClick={handleReplaceRecording}
          />
        </td>
        <td className="recording-details__table__cell">
          {(mediaPipeline.chatLogStatus === GenerateVttStatus.SUCCESS ||
            mediaPipeline.chatLogStatus ===
              GenerateVttStatus.MANUAL_UPDATE) && (
            <IconButton
              onClick={handleDownloadChat}
              size={SMALL}
              iconName="download"
              desc="Download chat log"
              btnId={`download-chat--${mediaPipeline.createdAt}`}
            />
          )}
        </td>
        <td className="recording-details__table__cell">
          {(mediaPipeline.attendeeLogStatus === GenerateVttStatus.SUCCESS ||
            mediaPipeline.attendeeLogStatus ===
              GenerateVttStatus.MANUAL_UPDATE) && (
            <IconButton
              onClick={handleDownloadAttendees}
              size={SMALL}
              iconName="download"
              desc="Download attendee list"
              btnId={`download-attendee--${mediaPipeline.createdAt}`}
            />
          )}
        </td>
        <td className="recording-details__table__cell">
          <Checkbox
            value={mediaPipeline.id}
            checked={mediaPipeline.id === selectedReplayRecording?.id}
            id={`mp_replay-recording_${key}`}
            onChange={() => handleSelectReplayRecording(mediaPipeline)}
          />
        </td>
        <td className="recording-details__table__cell recording-details__status">
          Video: {mediaPipeline.processRecordingStatus}
          <br />
          Events: {mediaPipeline.generateEventsStatus}
          <br />
          Chapters: {mediaPipeline.generateChaptersStatus}
          <br />
          Transcript: {mediaPipeline.generateCaptionsStatus}
          <br />
          Chat: {mediaPipeline.chatLogStatus}
        </td>
      </tr>
      {/* modals for all recordings are opening at the same time!!!! */}
      <UploadModal
        onDismiss={handleDismiss}
        display={showUploadForm}
        onComplete={() => handleUploadComplete(mediaPipeline.id)}
        maxFiles={1}
        validateFileType={validateFileType}
        generateS3Key={() => generateS3Key(mediaPipeline)}
        error={error}
        showOnCompleteError={showError}
        bucket={config.concatS3Bucket}
        title="Upload Video"
      />
    </Fragment>
  );
};
